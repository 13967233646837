import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Home from '../components/home'
import lang from '../data/en.json'

const IndexPage = ({ location, data }) => (
  <Layout
    lang={lang}
    location={location}
    isHomepage
    component={Home}
    logo={data.logo.sizes}
    images={data.allImageSharp.nodes.map(node => node.sizes)}
  />
)

export default IndexPage

export const pageQuery = graphql`
  query HomeEnQuery {
    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }

    allImageSharp(
      filter: { fluid: { originalName: { glob: "{background,bg}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
  }
`
